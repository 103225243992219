.nety_cookie {
  left: 0px;
  z-index: 999;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%)
  }
  100% {
    transform: translateY(0)
  }
}

@keyframes slideOutFromTop {
  0% {
    transform: translateY(-100%)
  }
  100% {
    transform: translateY(0)
  }
}

.nety_cookie p {
  margin-bottom: 0
}

.nety_cookie {
  position: fixed;
  min-height: 150px;
  width: 100%
}

.nety_cookie.opened {
  bottom: 0;
  animation: .5s ease-out 0s 1 slideInFromBottom
}

.nety_cookie.closed .nety_accept_button_container {
  display: none
}

.nety_cookie.closed {
  bottom: -300px !important;
  animation: .5s ease-out 0s 1 slideOutFromTop
}

.nety_cookie .nrow {
  width: 100%;
  padding: 20px
}

.nety_cookie h2 {
  color: rgba(255, 255, 255, 1);
  font-size: 16px
}

.nety_cookie p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  text-align: left
}

.nety_cookie a img {
  opacity: .7;
  width: 90px;
  height: 90px;
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto
}

.nety_cookie a:hover img {
  opacity: 1;
  width: 90px;
  height: 90px;
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto
}

.nety_a {
  color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
  width: 100%;
  text-align: center
}

.nety_cookie a small {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  width: 100%;
  text-align: center
}

.nety_a:hover, .nety_cookie a:hover small {
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  width: 100%;
  text-align: center;
  text-decoration: none !important
}

.nety_cookie a:hover {
  text-decoration: none !important
}

.nrow {
  clear: both;
  background-color: #c0392b;
  clear: both;
  display: block;
  overflow: auto;
  padding-bottom: 20px
}

.nety_accept_button_container {
  width: 10%;
  float: right;
  padding: 16px 16px 0 16px;
  margin-right: 1%;
  background-color: rgba(192, 57, 43, 1);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px
}

.nety_accept_button {
  padding: 10px;
  background-color: rgba(88, 28, 8, 0.9);
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  border-radius: 3px;
  font-size: 13px;
  line-height: 15px;
  display: block
}

.nety_accept_button:hover {
  padding: 10px;
  background-color: rgba(88, 28, 8, 1);
  color: rgba(255, 255, 255, 1);
  text-decoration: none !important
}

.nety_accept_button img {
  height: 18px !important;
  width: 18px !important;
  display: inline !important;
  opacity: 1 !important;
  position: absolute;
  margin-left: -22px !important;
  margin-top: -2px !important
}

@media only screen and (min-width: 640px) and (max-width: 1224px) {
  .nety_cookie .nrow .r1 {
    width: 40%;
    float: left;
    display: inline;
    padding: 20px;
    padding-bottom: 0
  }
  .nety_cookie .nrow .r2 {
    width: 20%;
    float: left;
    display: inline;
    text-align: center;
    padding-bottom: 0
  }
  .nety_cookie .nrow .r3 {
    width: 20%;
    float: left;
    display: inline;
    text-align: center;
    padding-bottom: 0
  }
  .nety_cookie .nrow .r3 a img {
    display: none
  }
  .nety_cookie .nrow .r2 a img {
    display: none
  }
  .nety_accept_button_container {
    width: 20%;
    float: right;
    padding: 16px 16px 0 16px;
    margin-right: 2%;
    background-color: #c0392b;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px
  }
}

@media only screen and (max-width: 640px) {
  .nety_cookie a {
    text-decoration: none !important
  }
  .nety_cookie .nrow .r1, .nety_cookie .nrow .r2, .nety_cookie .nrow .r3 {
    width: 100%;
    float: left;
    display: inline;
    padding: 0;
    padding-bottom: 0
  }
  .nety_cookie .nrow .r1.first h2, .nety_cookie .nrow .r1.first p, .nety_cookie .nrow .r1.secound p, .nety_cookie .nrow .r2 a img, .nety_cookie .nrow .r3 a img {
    display: none
  }
  .nety_cookie * {
    text-align: center
  }
  .nety_accept_button_container {
    width: 96%;
    float: right;
    padding: 16px 16px 0 16px;
    margin-right: 3%;
    background-color: #c0392b;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px
  }
  .nety_cookie a {
    text-decoration: none !important
  }
}

@media only screen and (min-width: 1224px) {
  .nety_cookie .nrow {
    display: flex
  }
  .nety_cookie a {
    text-decoration: none !important
  }
  .nety_cookie .nrow .r1 {
    width: 30%;
    float: left;
    display: inline;
    padding: 20px;
    padding-bottom: 0
  }
  .nety_cookie .nrow .r2 {
    width: 10%;
    float: left;
    display: inline;
    padding-bottom: 0
  }
  .nety_cookie .nrow .r3 {
    width: 10%;
    float: left;
    display: inline;
    text-align: center;
    padding-bottom: 0
  }
  .nety_cookie .borderleft {
    border-left: 1px solid rgba(255, 255, 255, 0.2)
  }
  .nety_cookie .borderright {
    border-right: 1px solid rgba(255, 255, 255, 0.2)
  }
}